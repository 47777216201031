import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import Background from './pages/Background';
import Practical from './pages/Practical';
import StarterClass from './pages/StarterClass';
import Docent from './pages/Docent';
import PastEvents from './pages/PastEvents';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="min-h-screen flex flex-col bg-gray-50">
        <Navbar />
        <main className="flex-grow pt-[142px]">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/achtergrond" element={<Background />} />
            <Route path="/docent" element={<Docent />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/aanmelden" element={<SignUp />} />
            <Route path="/praktisch" element={<Practical />} />
            <Route path="/lesaanbod" element={<StarterClass />} />
            <Route path="/eerdere-evenementen" element={<PastEvents />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;