import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormInput } from './forms/FormInput';
import { FormStatus } from './forms/FormStatus';

interface SignupFormProps {
  onSubmitSuccess?: () => void;
  selectedPlan: string;
}

export default function SignupForm({ onSubmitSuccess, selectedPlan }: SignupFormProps) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [countdown, setCountdown] = useState(10);
  
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    birthDate: '',
    emergencyContact: '',
    statutesAccepted: false,
    regulationsAccepted: false,
    communicationAccepted: false,
    directDebitAccepted: false,
    plan: selectedPlan
  });

  // Update plan when selectedPlan changes
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      plan: selectedPlan
    }));
  }, [selectedPlan]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (formSubmitted && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      navigate('/');
    }
    return () => clearTimeout(timer);
  }, [formSubmitted, countdown, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    
    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [target.name]: target.checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [target.name]: target.value
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('🚀 Starting form submission with data:', formData);

    setIsSubmitting(true);
    setSubmitStatus('idle');
    
    const payload = {
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      birthDate: formData.birthDate,
      emergencyContact: formData.emergencyContact,
      statutesAccepted: formData.statutesAccepted,
      regulationsAccepted: formData.regulationsAccepted,
      communicationAccepted: formData.communicationAccepted,
      directDebitAccepted: selectedPlan === 'monthly' ? formData.directDebitAccepted : null,
      plan: formData.plan
    };
    
    console.log('📡 Sending request to:', `${import.meta.env.VITE_API_URL}/prod/api/signup`);
    console.log('📦 Request payload:', payload);
    
    try {
      const apiUrl = import.meta.env.VITE_API_URL?.replace(/\/$/, ''); // Remove trailing slash if present
      const apiKey = import.meta.env.VITE_API_KEY;
      const endpoint = `${apiUrl}/api/signup`;
      
      console.log('🔑 API Key present:', !!apiKey);
      console.log('🌐 Full API URL:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey
        },
        body: JSON.stringify(payload)
      });

      console.log('📥 Response status:', response.status);
      console.log('📥 Response headers:', Object.fromEntries(response.headers.entries()));

      if (!response.ok) {
        const errorText = await response.text();
        console.error('❌ Response error:', errorText);
        throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      console.log('✅ Response data:', data);
      
      if (data) {
        setSubmitStatus('success');
        setFormSubmitted(true);
        onSubmitSuccess?.();
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return formSubmitted ? (
    <div className="p-8 bg-green-100 rounded-lg text-center">
      <h2 className="text-2xl font-bold text-green-700 mb-4">
        Bedankt voor je aanmelding!
      </h2>
      <p className="text-green-600 mb-6">
        We hebben je aanmelding ontvangen en nemen zo spoedig mogelijk contact met je op.
      </p>
      <div className="space-y-4">
        <button
          onClick={() => navigate('/')}
          className="text-[#D7790F] hover:text-[#A91A18] transition-colors"
        >
          Klik hier om terug te keren naar de homepage
        </button>
        <p className="text-sm text-green-600">
          Je wordt automatisch doorgestuurd in {countdown} seconden
        </p>
      </div>
    </div>
  ) : (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
      <div className="space-y-6">
        <FormInput
          id="name"
          name="name"
          label="Naam"
          type="text"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <FormInput
          id="phoneNumber"
          name="phoneNumber"
          label="Telefoonnummer"
          type="tel"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />

        <FormInput
          id="email"
          name="email"
          label="Email-adres"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <FormInput
          id="birthDate"
          name="birthDate"
          label="Geboortedatum"
          type="date"
          value={formData.birthDate}
          onChange={handleChange}
          required
        />

        <FormInput
          id="emergencyContact"
          name="emergencyContact"
          label="Telefoonnummer contactpersoon in geval van nood"
          type="tel"
          value={formData.emergencyContact}
          onChange={handleChange}
          required
        />

        <div className="space-y-4 pt-4">
          <h3 className="font-medium">Voorwaarden en communicatie</h3>
          
          <FormInput
            id="statutesAccepted"
            name="statutesAccepted"
            label="Ik ga akkoord met de statuten"
            type="checkbox"
            checked={formData.statutesAccepted}
            onChange={handleChange}
            required
            value={formData.statutesAccepted.toString()}
            title="Vink dit vakje aan om door te gaan"
          />

          <FormInput
            id="regulationsAccepted"
            name="regulationsAccepted"
            label="Ik ga akkoord met het huishoudelijk reglement"
            type="checkbox"
            checked={formData.regulationsAccepted}
            onChange={handleChange}
            required
            value={formData.regulationsAccepted.toString()}
            title="Vink dit vakje aan om door te gaan"
          />

          <FormInput
            id="communicationAccepted"
            name="communicationAccepted"
            label="Ik ga ermee akkoord dat bovenstaand email-adres en 06-nummer worden gebruikt voor communicatie vanuit de vereniging"
            type="checkbox"
            checked={formData.communicationAccepted}
            onChange={handleChange}
            required
            value={formData.communicationAccepted.toString()}
            title="Vink dit vakje aan om door te gaan"
          />

          {selectedPlan === 'monthly' && (
            <FormInput
              id="directDebitAccepted"
              name="directDebitAccepted"
              label="Ik ga akkoord met automatische incasso van de maandelijkse contributie"
              type="checkbox"
              checked={formData.directDebitAccepted}
              onChange={handleChange}
              required
              value={formData.directDebitAccepted.toString()}
              title="Vink dit vakje aan om door te gaan"
            />
          )}
        </div>
      </div>

      <FormStatus 
        status={submitStatus}
        successMessage="Registratie succesvol! Je wordt doorgestuurd naar de volgende stap."
        errorMessage="Er is een fout opgetreden bij het registreren. Probeer het opnieuw."
      />

      <button 
        type="submit"
        disabled={isSubmitting}
        className="btn-primary"
      >
        {isSubmitting ? 'Bezig met registreren...' : 'Registreren'}
      </button>
    </form>
  );
}