import React from 'react';
import EventCard from './EventCard';

interface Event {
  id: number;
  title: string;
  date: string;
  time: string;
  location: string;
  description: string;
  image: string;
}

interface EventsProps {
  events: Event[];
}

const upcomingEvents: Event[] = [
  {
    id: 1,
    title: "Zomercursus",
    date: "15-20 juli 2024",
    time: "9:00 - 16:00",
    location: "Hoofd dojo",
    description: "Vijf dagen intensief trainen met gastdocenten uit Japan. Geschikt voor gevorderde leerlingen.",
    image: "https://images.unsplash.com/photo-1574618464782-16f0c6dc4c6a?auto=format&fit=crop&q=80&w=1200"
  },
  {
    id: 2,
    title: "Beginnersworkshop",
    date: "1 juni 2024",
    time: "10:00 - 14:00",
    location: "Trainingshal B",
    description: "Inleiding in de basisprincipes van Aikido. Geschikt voor alle niveaus en perfect voor nieuwkomers.",
    image: "https://images.unsplash.com/photo-1555597673-b21d5c935865?auto=format&fit=crop&q=80&w=1200"
  },
  {
    id: 3,
    title: "Zwarte band examen",
    date: "5 augustus 2024",
    time: "13:00 - 17:00",
    location: "Hoofd dojo",
    description: "Kwartjaarlijkse zwarte band examen. Kom onze mede-beoefenaars steunen tijdens hun examen.",
    image: "https://images.unsplash.com/photo-1599229526921-4f29d42b0b41?auto=format&fit=crop&q=80&w=1200"
  }
];

export default function Events({ events = upcomingEvents }: EventsProps) {
  return (
    <section className="bg-white rounded-lg shadow-xl p-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Aankomende Evenementen</h2>
      <p className="text-gray-600 mb-8">
        Bekijk onze speciale trainingen, workshops en evenementen. Schrijf je in of neem contact op voor meer informatie.
      </p>
      <div className="space-y-6">
        {events.map((event) => (
          <div key={event.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={event.image}
              alt={event.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-xl font-bold text-gray-900 mb-2">{event.title}</h3>
              <p className="text-sm text-gray-600 mb-4">
                <span className="font-medium">Wanneer:</span> {event.date}, {event.time}<br />
                <span className="font-medium">Waar:</span> {event.location}
              </p>
              <p className="text-gray-700">{event.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}