import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-[#411217] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <div className="space-y-2">
              <p className="flex items-center"><Phone className="h-5 w-5 mr-2" /> 06-12471413</p>
              <p className="flex items-center"><Mail className="h-5 w-5 mr-2" /> info@anshinaikido.nl</p>
              <p className="flex items-center"><MapPin className="h-5 w-5 mr-2" /> 't Venster, Thomas A Kempislaan 82, 6822 LS Arnhem</p>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Trainingstijden</h3>
            <div className="space-y-2">
              <p>Dinsdag: 20:00 - 21:30</p>
              <p>Zondag: 10:30 - 12:00</p>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Volg ons</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com/profile.php?id=61569080980116" target="_blank" rel="noopener noreferrer" className="hover:text-[#D7790F]">Facebook</a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-center">
          <p>&copy; {new Date().getFullYear()} Anshin Aikido Arnhem. Alle rechten voorbehouden.</p>
        </div>
      </div>
    </footer>
  );
}