import React from 'react';

interface FormStatusProps {
  status: 'idle' | 'success' | 'error';
  successMessage: string;
  errorMessage: string;
}

export function FormStatus({ status, successMessage, errorMessage }: FormStatusProps) {
  if (status === 'idle') return null;

  return (
    <>
      {status === 'success' && (
        <div className="p-4 bg-green-100 text-green-700 rounded-lg">
          {successMessage}
        </div>
      )}
      {status === 'error' && (
        <div className="p-4 bg-red-100 text-red-700 rounded-lg">
          {errorMessage}
        </div>
      )}
    </>
  );
}
