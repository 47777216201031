import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '/images/Anshin_Logo.svg';
import anshinWordLogo from '/images/anshin_woord_logo.svg';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [showLesaanbodSubmenu, setShowLesaanbodSubmenu] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setShowLesaanbodSubmenu(false);
  };

  const navigation = [
    { name: 'Home', href: '/' },
    { name: 'Achtergrond', href: '/achtergrond' },
    { 
      name: 'Lesaanbod',
      href: '/lesaanbod',
      submenu: [
        { name: 'Docent', href: '/docent' },
        { name: 'Eerdere evenementen', href: '/eerdere-evenementen' }
      ]
    },
    { name: 'Praktisch', href: '/praktisch' },
    { name: 'Aanmelden', href: '/aanmelden' },
  ];

  return (
    <nav className="fixed w-full z-50">
      <div className="bg-gradient-to-r from-white via-[#D7790F] to-[#A91918]">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-24 sm:h-32">
            {/* Logo section with title */}
            <div className="flex-1">
              <div className="flex flex-col items-end mr-1 sm:mr-2">
                <h1 className="leading-none mb-1 sm:mb-2">
                  <img
                    src={anshinWordLogo}
                    alt="Anshin"
                    className="h-[20px] sm:h-[50px] w-auto"
                  />
                </h1>
                <div className="text-lg sm:text-2xl whitespace-nowrap">
                  <span className="font-['Inter'] text-[#A91918] font-medium">Aikido vereniging Arnhem</span>
                </div>
              </div>
            </div>

            {/* Center Logo */}
            <div className="flex-1 flex justify-center items-center">
              <Link to="/" className="flex items-center justify-center" onClick={closeMenu}>
                <img
                  className="h-[80px] sm:h-[144px] w-auto max-w-none"
                  src={logo}
                  alt="Anshin Aikido"
                />
              </Link>
            </div>

            {/* Mobile menu button */}
            <div className="flex-1 flex justify-start pl-4 md:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-white/10"
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <svg
                    className="block h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex-1 md:flex md:flex-col md:justify-end md:h-32">
              <div className="hidden md:flex justify-end space-x-6">
                {navigation.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.submenu ? (
                      <div 
                        className="relative group flex items-end"
                        onMouseEnter={() => setShowLesaanbodSubmenu(true)}
                        onMouseLeave={() => setShowLesaanbodSubmenu(false)}
                      >
                        <Link
                          to={item.href}
                          className="text-lg text-white hover:bg-white/10 px-4 py-3 rounded-t-md font-medium"
                        >
                          {item.name}
                        </Link>
                        <div 
                          className={`absolute top-full left-0 w-96 rounded-b-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 transform transition-all duration-200 ${
                            showLesaanbodSubmenu ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1 pointer-events-none'
                          }`}
                        >
                          {item.submenu.map((submenuItem, submenuIndex) => (
                            <Link
                              key={submenuIndex}
                              to={submenuItem.href}
                              className="block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100"
                            >
                              {submenuItem.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={item.href}
                        className="text-lg text-white hover:bg-white/10 px-4 py-3 rounded-t-md font-medium"
                        onClick={closeMenu}
                      >
                        {item.name}
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden bg-white shadow-lg absolute w-full top-[96px] sm:top-[128px]`}>
        <div className="px-4 pt-2 pb-4 space-y-2 sm:px-6">
          {navigation.map((item, index) => (
            <React.Fragment key={index}>
              {item.submenu ? (
                <div>
                  <div className="flex items-center justify-between px-4 py-2 rounded-md text-lg font-medium text-gray-700 hover:bg-gray-100">
                    <Link
                      to={item.href}
                      className="flex-grow"
                      onClick={closeMenu}
                    >
                      {item.name}
                    </Link>
                    <button
                      onClick={() => setShowLesaanbodSubmenu(!showLesaanbodSubmenu)}
                      className="ml-2"
                      aria-label="Toggle submenu"
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={showLesaanbodSubmenu ? "M5 15l7-7 7 7" : "M19 9l-7 7-7-7"}
                        />
                      </svg>
                    </button>
                  </div>
                  {showLesaanbodSubmenu && (
                    item.submenu.map((submenuItem, submenuIndex) => (
                      <Link
                        key={submenuIndex}
                        to={submenuItem.href}
                        className="block pl-8 py-2 text-lg font-medium text-gray-700 hover:bg-gray-100"
                        onClick={closeMenu}
                      >
                        {submenuItem.name}
                      </Link>
                    ))
                  )}
                </div>
              ) : (
                <Link
                  to={item.href}
                  className="block px-4 py-2 rounded-md text-lg font-medium text-gray-700 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  {item.name}
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </nav>
  );
}