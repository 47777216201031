import React from 'react';

export default function PastEvents() {
  const pastEvents = [
    {
      id: 1,
      title: "Seminar Ivan Marinovic",
      date: "3 december 2024",
      description: "Bijzonder seminar onder leiding van Ivan Marinovic Sensei (3e dan). Ivan gaf de lancering van onze nieuwe dojo extra vleugels door een seminar over het thema 'Safe space' te geven. Wat is een veilige omgeving? Een plek waar je jezelf kan zijn, met ook minder fijne gevoelens en onzekerheden. Een plek waar je fouten mag maken. Een plek waar je je rollen kunt loslaten, kunt opladen, kunt helen. Een plek waar je gek kan doen. Een plek van Vertrouwen.",
      location: "'t Venster, Arnhem",
      image: "/images/ivan-marinovic-seminar.jpg"
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
      <h1 className="text-4xl font-bold mb-8">Eerdere Evenementen</h1>
      
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
        {pastEvents.map((event) => (
          <div key={event.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="h-48 w-full overflow-hidden">
              <img
                src={event.image}
                alt={event.title}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
              <p className="text-gray-600 mb-2">{event.date}</p>
              <p className="text-gray-600 mb-4">{event.location}</p>
              <p className="text-gray-700">{event.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
