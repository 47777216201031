import React from 'react';

export default function Background() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
      <h1 className="text-4xl font-bold mb-8">De achtergrond van Aikido</h1>
      
      <div className="prose prose-lg max-w-none">
        <section className="mb-12">
          <div className="mb-8">
            <img
              src="/images/Ueshiba_Shinto.jpg"
              alt="O-Sensei Morihei Ueshiba bij een Shinto schrijn"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </div>
          
          <div className="md:flex md:gap-8 items-start">
            <div className="md:flex-1">
              <p className="mb-4">
              Aikido's oprichter, Morihei Ueshiba, is 14 december 1883 geboren. Naar verluid zag hij als kind verschillende keren mensen zijn vader vanwege politieke onrusten in elkaar slaan, en was dit voor hem aanleiding om zich weerbaar te willen maken middels hard te trainen in jiu jitsu, zwaardvechten en speervechten. Ondanks zijn verworven meesterschap en fysieke fitheid, bracht het hem geen voldoening. Hij begon zich spiritueel te ontwikkelen, in de hoop meer betekenisgeving te kunnen vinden in het leven, terwijl hij zich bleef ontwikkelen in de krijgskunst.
              </p>
              <p className="mb-4">
              In 1942 heeft hij de moderne krijgskunst Aikido opgericht vanuit zijn combinatie martiale achtergrond, spirituele en politieke ideologie. Religieus gezien was Ueshiba aanhanger van 'Omotokyo' (neo-shintoisme en socio-politiek idealisme). Het doel van Omotokyo is het verenigen van de mensheid in een wereld waar alle religies naast elkaar mogen bestaan. Veel van O-Sensei's uitspraken dienen ook te worden gelezen met de invloed van Omotokyo in het achterhoofd.
              </p>
              <p>
              Tijdens zijn doorontwikkeling van Aikido, heeft Ueshiba zelf ook ontwikkeling doorgemaakt. Zo hebben de leerlingen die eerder bij hem trainden een andere stijl Aikido doorgegeven aan hun leerlingen, dan leerlingen die later in Ueshiba's leven bij hem trainden. Hier kan worden gezien dat de bewegingen in het begin dichter bij jiu jitsu lagen, en over de tijd steeds vloeiender en vrijer werden met toenemende nadruk op 'ki'.
              </p>
            </div>
            <div className="md:w-1/3 mt-32 md:mt-32"> 
              <img
                src="/images/Kokyo_O_sensei.jpg"
                alt="O-Sensei Morihei Ueshiba demonstreert kokyu"
                className="rounded-lg shadow-lg w-full h-auto sticky top-24"
              />
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">Uitspraken van O'Sensei</h2>
          <div className="md:flex md:gap-8 items-start">
            <div className="md:w-1/2">
              <blockquote className="quote">
                "Aikido is niet om vijanden te bevechten of te verslaan, het is een manier om de wereld te verenigen en alle mensen een familie te laten zijn."
              </blockquote>
              <blockquote className="quote">
                "De essentie van Aikido is om ki (levensenergie, interne kracht, spirituele/mentale energie) te cultiveren."
              </blockquote>
              <blockquote className="quote">
                "Echte overwinning is niet over een tegenstander, maar over de disharmonie in jezelf."
              </blockquote>
              <blockquote className="quote">
                "Aikido is in eerste plaats een manier om fysieke en psychische zelfbeheersing te bereiken"
              </blockquote>
              <blockquote className="quote mb-8">
                "Het lichaam is de gemanifesteerde vereniging van het fysieke en het spirituele."
              </blockquote>

              <p className="mb-2">In de basis vraagt beoefening van Aikido twee zaken:</p>
              <ul className="list-disc list-inside">
                <li className="mt-2">Een toewijding aan vreedzame conflictoplossing waar mogelijk.</li>
                <li className="mt-2">Een toewijding aan persoonlijke ontwikkeling door Aikido training.</li>
              </ul>
            </div>
            <div className="md:w-1/3 mt-4 md:mt-0"> 
              <img
                src="/images/Ueshiba_Walking.jpg"
                alt="O-Sensei Morihei Ueshiba wandelend"
                className="rounded-lg shadow-lg w-full h-auto"
              />
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">The difference with other martial arts</h2>
          <div className="bg-gray-50 p-6 rounded-lg border border-gray-200">
            <p className="text-gray-600 italic mb-4">
              "In my opinion, it can be said to be the true martial art. The reason for this is that it is a martial art based on universal truth. This Universe is composed of many different parts, and yet the Universe as a whole is united as a family and symbolizes the ultimate state of peace.
              Holding such a view of the Universe, aikido cannot be anything but a martial art of love. It cannot be a martial art of violence. For this reason, aikido can be said to be another manifestation of the Creator of the Universe. In other words, aikido is like a giant (immense in nature). Therefore, in aikido, Heaven and Earth become the training grounds.
              The state of mind of the aikidoist must be peaceful and totally non-violent. That is to say, that special state of mind which brings violence into a state of harmony. And this I think is the true spirit of Japanese martial arts. We have been given this earth to transform into a heaven on earth. War-like activity is totally out of place."
            </p>
            <p className="text-gray-500 text-sm mt-4">- Morihei Ueshiba (O-Sensei)</p>
          </div>
        </section>
      </div>
    </div>
  );
}
