import React, { useState } from 'react';
import SignupForm from '../components/SignupForm';

export default function SignUp() {
  const plans = [
    {
      id: 'monthly',
      name: 'Lidmaatschap',
      price: 35,
      interval: 'per maand',
      description: [
        'Onbeperkt trainen',
        'Toegang tot de ledenvergadering',
        'Toegang tot verenigingsactiviteiten'
      ]
    },
    {
      id: 'strippenkaart',
      name: 'Strippenkaart',
      price: 30,
      interval: 'voor 4 lessen',
      description: [
        '4 lessen naar keuze',
        'Geldig voor 3 maanden',
        'Flexibel in te plannen'
      ],
      note: 'Geen toegang tot ALV of verenigingsactiviteiten'
    }
  ];

  const [selectedPlan, setSelectedPlan] = useState(plans[0].id);
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Aanmelden</h1>
        <p className="text-xl text-gray-600">
          Wat fijn dat je wilt komen trainen!
        </p>
      </div>

      {!formSubmitted && (
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {plans.map((plan) => (
            <div 
              key={plan.id}
              className={`bg-white rounded-lg shadow-lg p-8 border-2 transition-all cursor-pointer hover:shadow-xl
                ${selectedPlan === plan.id ? 'border-[#D7790F]' : 'border-transparent'}`}
              onClick={() => setSelectedPlan(plan.id)}
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-semibold">{plan.name}</h3>
                  <p className="text-gray-500 text-sm mt-1">
                    €{plan.price} {plan.interval}
                  </p>
                </div>
              </div>
              
              <ul className="space-y-3 mb-4">
                {plan.description.map((item, index) => (
                  <li key={index} className="flex items-start">
                    <svg
                      className="h-5 w-5 text-[#D7790F] mr-2 mt-0.5 flex-shrink-0"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span className="text-gray-600">{item}</span>
                  </li>
                ))}
              </ul>
              
              {plan.note && (
                <p className="text-sm text-gray-500 mt-4 italic">
                  {plan.note}
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="border-t pt-8">
          <SignupForm 
            onSubmitSuccess={() => setFormSubmitted(true)}
            selectedPlan={selectedPlan}
          />
        </div>
      </div>
    </div>
  );
}