import React from 'react';

interface FormInputProps {
  id: string;
  name: string;
  label: string;
  value: string | boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  type?: string;
  required?: boolean;
  as?: 'input' | 'select' | 'textarea';
  options?: { value: string; label: string; }[];
  rows?: number;
  checked?: boolean;
  title?: string;
}

export function FormInput({ 
  label, 
  type = 'text', 
  id, 
  name, 
  value, 
  onChange, 
  required = false,
  as = 'input',
  options,
  rows,
  checked,
  title
}: FormInputProps) {
  const baseClassName = "w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-600 focus:border-transparent";

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      {as === 'input' && (
        <input
          type={type}
          id={id}
          name={name}
          value={value.toString()}
          onChange={onChange}
          required={required}
          checked={type === 'checkbox' ? checked : undefined}
          className={type === 'checkbox' ? 'mr-2' : baseClassName}
          title={title}
        />
      )}
      {as === 'select' && (
        <select
          id={id}
          name={name}
          value={value.toString()}
          onChange={onChange}
          className={baseClassName}
        >
          {options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      )}
      {as === 'textarea' && (
        <textarea
          id={id}
          name={name}
          value={value.toString()}
          onChange={onChange}
          rows={rows}
          className={baseClassName}
        />
      )}
    </div>
  );
}
