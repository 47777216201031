import React, { useState } from 'react';
import { FormInput } from './forms/FormInput';
import { FormStatus } from './forms/FormStatus';


interface FormData {
  name: string;
  email: string;
  phone: string;
  preferred_date: string;
  experience_level: string;
  message: string;
}


export default function StarterClassForm() {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    preferred_date: '',
    experience_level: 'none',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');
    
    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      preferred_date: formData.preferred_date,
      experience_level: formData.experience_level,
      message: formData.message
    };

    console.log('📡 Sending request to:', `${import.meta.env.VITE_API_URL}/prod/api/starter-class`);
    console.log('📦 Request payload:', payload);
    
    try {
      // Debug logging
      console.log('🔍 Environment variables:', {
        VITE_API_URL: import.meta.env.VITE_API_URL,
        VITE_DISTRIBUTION_URL: import.meta.env.VITE_DISTRIBUTION_URL,
        VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT
      });

      if (!import.meta.env.VITE_API_URL || import.meta.env.VITE_API_URL.includes('cloudfront')) {
        throw new Error('Invalid API URL configuration. Check your environment variables.');
      }

      const apiUrl = import.meta.env.VITE_API_URL?.replace(/\/$/, ''); // Remove trailing slash if present
      const apiKey = import.meta.env.VITE_API_KEY;
      const endpoint = `${apiUrl}/api/starter-class`;
      
      console.log('🔑 API Key present:', !!apiKey);
      console.log('🌐 Full API URL:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey
        },
        body: JSON.stringify(payload)
      });

      console.log('📥 Response status:', response.status);
      console.log('📥 Response headers:', Object.fromEntries(response.headers.entries()));
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('❌ Server response:', {
          status: response.status,
          statusText: response.statusText,
          error: errorData
        });
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('✅ Registration successful:', data);
      setSubmitStatus('success');
    } catch (error) {
      console.error('❌ Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormInput
          label="Naam"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <FormInput
          label="E-mailadres"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="space-y-6">
        <FormInput
          label="Telefoonnummer"
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        
        <FormInput
          label="Gewenste Startdatum"
          type="date"
          id="preferred_date"
          name="preferred_date"
          value={formData.preferred_date}
          onChange={handleChange}
          required
        />

        <FormInput
          label="Ervaring met Martial Arts"
          id="experience_level"
          name="experience_level"
          value={formData.experience_level}
          onChange={handleChange}
          as="select"
          options={[
            { value: 'none', label: 'Geen ervaring' },
            { value: 'beginner', label: 'Beginner (0-1 jaar)' },
            { value: 'intermediate', label: 'Gemiddeld (1-3 jaar)' },
            { value: 'advanced', label: 'Gevorderd (3+ jaar)' }
          ]}
        />

        <FormInput
          label="Aanvullende Bericht"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          as="textarea"
          rows={4}
        />
      </div>

      <FormStatus
        status={submitStatus}
        successMessage="Bedankt voor je aanmelding! We nemen binnenkort contact met je op om je proefles te bevestigen."
        errorMessage="Er is een fout opgetreden bij het versturen van je aanmelding. Probeer het opnieuw."
      />

      <button
        type="submit"
        disabled={isSubmitting}
        className="btn-primary"
      >
        {isSubmitting ? 'Bezig met versturen...' : 'Aanmelden voor Proefles'}
      </button>
    </form>
  );
}