import React from 'react';
import { Link } from 'react-router-dom';
import StarterClassForm from '../components/StarterClassForm';

export default function StarterClass() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="mb-12">
        <h1 className="text-4xl font-bold mb-4">Lesaanbod</h1>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        {/* Left Column - Content */}
        <div className="space-y-8">
          {/* Stijl Section */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#D7790F]">Stijl</h2>
            <p className="text-gray-600">
              Anshin is onderdeel van de Evolutionary Aikido Community, de lijn van Patrick Cassidy, 7e dan. 
              Deze lijn volgt de groei die O'Sensei gedurende zijn aikido-pad heeft bewandeld verder door; 
              vanuit de gedachte dat O'Sensei echt een uniek perspectief op conflict had. De fysieke aanval 
              die de basis vormt van elke aikido-techniek staat hierbij tevens symbool voor conflict in 
              bredere zin: op je werk, in je familie, zelfs intern. Hoe blijf je in verbinding en in evenwicht? 
              De lessen maken daarom vaak een parallel naar het dagelijks leven.
            </p>
          </div>

          {/* Image Section */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img 
              src="/images/aikido-training.webp" 
              alt="Aikido training bij Anshin" 
              className="w-full h-auto object-cover"
            />
          </div>

          {/* Wat doen we in de lessen? Section */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#D7790F]">Wat doen we in de lessen?</h2>
            <p className="text-gray-600 mb-4">
              Veelal technieken zijn gericht op loskomen uit pakkingen/ grepen, en slagen of stoten, 
              met hierbij nadruk op rust in jezelf onder druk. Leren rollen en valbreken is ook een 
              vast onderdeel. Naast deze tachi-waza, integreren we ook veel Iwama-stijl wapenwerk (buki-waza). 
              Werken met het zwaard (boken) en de staf (jo) leert je namelijk enorm veel over je lichaamshouding, 
              je balans, afstand en timing. Zie voor het technisch curriculum de exameneisen onder tabblad 
              Praktische informatie.
            </p>
            <p className="text-gray-600">
              We werken voornamelijk vloeiend, om te leren vertrouwen op de natuurlijke beweging, en uit 
              het hoofd te raken. Meditatie en reflectieve dialoog zijn daarom ook onderdeel van de lessen, 
              en geregeld zetten we tijdens het trainen lekkere muziek op.
            </p>
          </div>

          {/* Waar staan we voor? Section */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#D7790F]">Waar staan we voor?</h2>
            <p className="text-gray-600">
              Bij Anshin staan we voor hoogwaardig aikido. Door aikido kunnen mensen conflicten leren 
              hanteren op een geweldloze manier. We staan voor een veilige plek waar persoonlijke groei 
              gefaciliteerd wordt, Een plek waar wordt gelachen, een traan er gewoon mag zijn, vertrouwen 
              gegund wordt en flow ontstaat. Een plek van gelijkwaardigheid, verbinding, en lekker samen bewegen.
            </p>
          </div>

          {/* Wie geeft les? Section */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-[#D7790F]">Wie geeft les?</h2>
            <p className="text-gray-600 mb-4">
              Voor meer informatie over de docent, zie{' '}
              <Link to="/docent" className="text-[#D7790F] hover:text-[#A91A18] transition-colors">
                Lesaanbod - docent
              </Link>
            </p>
          </div>
        </div>

        {/* Right Column - Form */}
        <div className="bg-white rounded-lg shadow-lg p-8 h-fit sticky top-8">
          <h2 className="text-2xl font-semibold mb-6 text-[#D7790F]">Schrijf je in voor een proefles</h2>
          <StarterClassForm />
        </div>
      </div>
    </div>
  );
}